import { CustomFieldComponentType } from '~/components/CustomField'
import { ApplicationField } from '~/lib/features/jobs/types/application-fields'
import {
  CustomFieldFormType,
  CustomFieldResponseItem
} from '../types/custom-field'

export const mappingTypeToFieldKind = (type: string): string => {
  const dataMapping: { [key: string]: string } = {
    text: 'string',
    paragraph: 'text',
    number: 'number',
    toggle: 'boolean',
    select: 'array',
    date: 'date'
  }
  return dataMapping?.[type] || 'string'
}

export const mappingCustomFieldKind = (
  fieldKind: string
): CustomFieldComponentType['type'] => {
  const dataMapping: { [key: string]: CustomFieldComponentType['type'] } = {
    string: 'text',
    text: 'paragraph',
    number: 'number',
    boolean: 'toggle',
    array: 'select',
    date: 'date'
  }
  return dataMapping?.[fieldKind] || 'text'
}

export const renderKeyCustomFieldForm = ({
  fieldKind,
  id
}: {
  fieldKind: string
  id: number | string
}) => `${id}-${fieldKind}`

export const formatInitialValueCustomField = (
  data?: Array<CustomFieldResponseItem | ApplicationField>,
  extraConfig?: { isNotTrimText: boolean }
): CustomFieldFormType['customFields'] => {
  if (!data || data.length === 0) return {}

  return data.reduce((result, item) => {
    const fieldKind =
      (item as CustomFieldResponseItem).fieldKind ||
      (item as ApplicationField).field_kind ||
      ''

    const id =
      (item as CustomFieldResponseItem).customSettingId ||
      (item as ApplicationField).custom_field_id ||
      ''

    const customFieldType = mappingCustomFieldKind(fieldKind)

    const key = renderKeyCustomFieldForm({ fieldKind, id })

    // Extract value handling into a function for clarity
    const formattedValue = formatCustomFieldValue(
      item,
      customFieldType,
      extraConfig
    )

    //@ts-ignore
    result[key] = {
      ...item,
      id,
      type: customFieldType,
      //@ts-ignore
      value: formattedValue
    }

    return result
  }, {} as CustomFieldFormType['customFields'])
}

const formatCustomFieldValue = (
  item: CustomFieldResponseItem | ApplicationField,
  customFieldType: string,
  extraConfig?: { isNotTrimText: boolean }
) => {
  const value = (item as CustomFieldResponseItem).value

  if (customFieldType === 'date') {
    return value && Object.keys(value).length
      ? value
      : { year: null, month: null, date: null }
  }

  if (customFieldType === 'select') {
    return (item as CustomFieldResponseItem).selectedOptionKeys?.[0]
  }

  if (typeof value === 'string' && !extraConfig?.isNotTrimText) {
    return value.trim()
  }

  return value
}

export const formatSubmitCustomFieldData = (
  data?: CustomFieldFormType['customFields'],
  extraConfig?: {
    isNotTrimText: boolean
  }
) => {
  return Object.values(data || {}).map((item) => {
    return {
      index: Number(item.index),
      fieldKind: mappingTypeToFieldKind(item.type),
      customSettingId: Number(item.id),
      value:
        item.type === 'date'
          ? Object.keys(item.value || {}).length
            ? item.value
            : { year: null, month: null, date: null }
          : item.type === 'select'
          ? ''
          : typeof item.value === 'string' && !extraConfig?.isNotTrimText
          ? item.value.trim()
          : item.value || '',
      selectedOptionKeys: item.type === 'select' ? [item.value] : []
    }
  })
}
